import React from "react";

export const Footer = () => {
    return (
        <p style={{fontSize: 14}}>
            <hr/>
            <a href="mailto:support@rewardster.app" style={{color: 'white'}}>Contact Support</a> | <a href="/unsubscribe" style={{color: 'white'}}>Unsubscribe</a> | <a href="/privacy.html" target="_blank" style={{color: 'white'}}>Privacy Policy</a> | <a href="/terms.html" target="_blank" style={{color: 'white'}}>Terms of Service</a>
        </p>
    )
}
