import React from 'react';
import '../App.css';
import {Footer} from "../components/footer";

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <a href="/"><img src="https://rewardster.s3.us-west-2.amazonaws.com/splash.png" className="App-logo" alt="Rewardster App" /></a>
        <p>
          Rewardster App!
        </p>
          <Footer/>
      </header>
    </div>
  );
}

export default App;
