import {ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache} from "@apollo/client";
import {onError} from "@apollo/client/link/error";
// import ApolloLinkTimeout from 'apollo-link-timeout';

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL
});

// const timeoutLink = new ApolloLinkTimeout(10000);

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError)  {
        // Toast.show({
        //     type: 'error',
        //     text1: 'Uh Oh!',
        //     text2: 'Unable to reach our servers. Please check your connection',
        // });
    }
});


const errorMiddleware = new ApolloLink((operation, forward) => {
    return forward(operation).map((response: any) => {
        let err = null;

        if (response.errors && response.errors.length > 0) {
            err = response.errors[0];
            // let msg = "Unexpected Error. Please try again";
            if (err.extensions && err.extensions.code && err.extensions.code === 'Friendly') {
                // msg = err.message;
            } else {
                console.error(err.message)
            }

            // console.error(msg)
            // Toast.show({
            //     type: 'error',
            //     text1: 'Uh Oh!',
            //     text2: msg,
            // });
        }

        return response;
    });
});

// const authMiddleware = setContext(async (req, { headers }) => {
//     const token = await getValueFor("auth");
//
//     return {
//         headers: {
//             ...headers,
//             Authorization: token
//         },
//     };
// });

const cache = new InMemoryCache();

export const client = new ApolloClient({
    cache,
    link: from([errorLink, errorMiddleware, httpLink]),
});

export async function mutate(query: any, variables: any, refetchQueries?: any): Promise<any> {
    try {
        const data: any = await client.mutate({
                mutation: query,
                variables,
                refetchQueries
            },
        );

        return data.data;
    } catch (err) {
        return null;
    }
}

export async function query(query: any, variables: any): Promise<any>
{
    try {
        const data: any = await client.query({
                query,
                variables,
                fetchPolicy: 'network-only'
            },
        );

        return data.data;
    } catch (err) {
        return null;
    }
}
