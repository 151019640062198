import React, {useState} from 'react';
import '../App.css';
import {gql} from "@apollo/client";
import {mutate} from "../apollo";
import {Footer} from "../components/footer";

function Unsubscribe() {
    // console.log(process.env.REACT_APP_API_URL)
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [done, setDone] = useState(false)

    React.useEffect(() => {

        const params = new URLSearchParams(window.location.search);

        let email = (params.get('email') || '').toString()
        setEmail(email);

    }, [])


    const submit = async () => {
        setLoading(true);

        const query = gql`
            mutation Mutate($email: String!) {
                unsubscribe(email: $email)
            }
        `;

        const vars = {
            email
        }

        const response = await mutate(query, vars);

        console.log(response);

        setDone(true);
        setLoading(false);
    }

    return (
    <div className="App">
      <header className="App-header">
          <a href="/"><img src="https://rewardster.s3.us-west-2.amazonaws.com/splash.png" className="App-logo" alt="Rewardster App" /></a>
        <p>
          Rewardster App! - Unsubscribe
        </p>

          <div style={{textAlign: 'left', padding: 10, fontSize: 13}}>
              Please enter your email address below. You will be remove from all future email communications.
          </div>

          {done ?
              <>
                  <div style={{textAlign: 'left', padding: 10, fontSize: 15, fontWeight: 'bold'}}>
                      Your email has been unsubscribed.
                  </div>
              </> :
              <div style={{flex: 'none'}}>
                  <input type="text" placeholder="Email Address" value={email}
                         onChange={(event) => setEmail(event.target.value)}/> <input type="Button" disabled={loading}
                                                                                     value={loading ? "Please wait..." : "Submit"}
                                                                                     readOnly={true} onClick={submit}/>
              </div>
          }

          <Footer/>
      </header>
    </div>
  );
}

export default Unsubscribe;
